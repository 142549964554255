<template>
  <div class="home">
    <div class="banner">
      <img src="@/assets/banner.png" />
    </div>
    <div class="panel">
      <van-grid :column-num="3" :gutter="10" :border="false">
        <van-grid-item>
          <router-link to="/map">
            <van-image
              width="45"
              height="45"
              :src="require('@/assets/icon-home-1.svg')"
            />
            热度地图
          </router-link>
        </van-grid-item>

        <van-grid-item>
          <router-link to="/booklist">
            <van-image
              width="45"
              height="45"
              :src="require('@/assets/icon-home-2.svg')"
            />
            推荐读物
          </router-link>
        </van-grid-item>

        <van-grid-item>
          <router-link to="/newslist">
            <van-image
              width="45"
              height="45"
              :src="require('@/assets/icon-home-3.svg')"
            />
            活动资讯
          </router-link>
        </van-grid-item>
      </van-grid>
      <p>
        为贯彻落实全国妇联、中国科协《关于开展巾帼科普活动的通知》精神，按照河北省妇联等九部门《关于深化拓展爱心爸妈结对关爱留守儿童和困境儿童志愿服务三年行动实施方案》的安排，深入实施“雏鹰展翅助飞行动”，省妇联拟在实施爱心爸妈结对关爱帮扶工作中推出“阅”享童年志愿服务活动，指导陪伴留守儿童和困境儿童学习科学知识、提升科学素养、全面健康成长。
        <!-- {{ message }} -->
      </p>
    </div>
    <div class="footer">
      指导单位：全国妇联宣传部<br />
      主办单位：河北省妇联
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import { fetchOptions } from "@/network/API";
export default {
  name: "HomeView",
  components: {},
  data() {
    return {
      message: "",
    };
  },
  created() {
    this.getHomeData();
  },
  methods: {
    async getHomeData() {
      var data = await fetchOptions({
        key: "intro",
      });
      this.message = data;
    },
  },
};
</script>
<style lang="scss" scoped>
.panel {
  margin: -100px 16px 16px;
  padding: 60px 16px 16px;
  border-radius: 15px;
  background: #ffffff;
  box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.1);
  ::v-deep .van-grid-item__content {
    padding: 12px 8px;
    border-radius: 25px;
    color: #fff;
    background: #35befa;
    box-shadow: inset 0px 4px 10px 0px rgba(255, 255, 255, 0.3);
    a {
      display: flex;
      justify-content: center;
      align-items: center;
      color: #fff;
      flex-direction: column;
      .van-image {
        margin-bottom: 6px;
      }
    }
  }
  p {
    font-size: 14px;
    line-height: 1.6;
    text-indent: 28px;
  }
}
.footer {
  color: #fff;
  text-align: center;
  font-size: 15px;
  line-height: 1.8;
  padding: 16px 0;
}
</style>
